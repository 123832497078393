const coefficient_income = {
    name: 'coefficient_income',
    title: 'Коэффициент начислений',
    url: '/settings/coefficient_income',
    include: [],
    filterBy: ['coefficient'],
    perms: {
        list: 'v1.coefficient_income.list',
        read: 'v1.coefficient_income.read',
        create: 'v1.coefficient_income.create',
        update: 'v1.coefficient_income.update',
        delete: 'v1.coefficient_income.delete',
    },
    listColumns: [
        {
            name: 'coefficient',
            required: true,
            label: 'Коэффициент',
            align: 'left',
            field: 'coefficient',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
    editColumns: [
        {
            name: 'coefficient',
            required: true,
            label: 'Коэффициент',
            align: 'left',
            field: 'coefficient',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
};

export {
    coefficient_income,
};
